import { Dispatch, FC, SetStateAction } from 'react'

import {
  FindImproverGoalMemberGridQuery,
  FindImproverGoalMemberGridQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { useAuth } from 'src/Providers'

import GoalList from '../GoalList'

export const QUERY = gql`
  query FindImproverGoalMemberGridQuery(
    $adminEditMode: Boolean!
    $membershipId: Int
  ) {
    goals: improverGoalMembersAll(adminEditMode: $adminEditMode) {
      ...ImproverGoalMemberFields
      aclPrincipals {
        ...ACLFields
        principal {
          __typename
          ... on ACLPrincipalMembership {
            membership {
              id
              user {
                id
                name
                avatarUrl
              }
            }
          }
          ... on ACLPrincipalMembershipGroup {
            membershipGroup {
              id
              name
            }
          }
        }
      }
      childGoals(adminEditMode: $adminEditMode, membershipId: $membershipId) {
        ...ImproverGoalMemberFields
        aclPrincipals {
          ...ACLFields
          principal {
            __typename
            ... on ACLPrincipalMembership {
              membership {
                id
                user {
                  id
                  name
                  avatarUrl
                }
              }
            }
            ... on ACLPrincipalMembershipGroup {
              membershipGroup {
                id
                name
              }
            }
          }
        }
      }
    }
    memberships: membershipsByClient {
      id
      user {
        id
        name
        avatarUrl
      }
    }
    membershipGroups: membershipGroupsByClient {
      id
      name
      parentGroup {
        id
        name
      }
      childGroup {
        id
        name
      }
    }
  }
`

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'cache-and-network',
})
export const Loading = () => (
  <div
    className="flex items-center justify-center"
    style={{ minHeight: 'calc(100vh - 80px)' }}
  >
    <LoadingSpinner />
  </div>
)

interface GoalListCellProps {
  clientId: number
  membershipId: number
  userHasAdminAccess: boolean
  adminEditMode: boolean
  setAdminEditMode: Dispatch<SetStateAction<boolean>>
  pathname: string
  templateId: number | undefined | null
  goalId?: number
}
type Props = CellSuccessProps<
  FindImproverGoalMemberGridQuery,
  FindImproverGoalMemberGridQueryVariables
> &
  GoalListCellProps
export type GoalListCellMembershipGroup = Props['membershipGroups'][0]
export type GoalListCellMembership = Props['memberships'][0]
export const Empty: FC<Props> = ({
  clientId,
  membershipId,
  goals,
  memberships,
  membershipGroups,
  userHasAdminAccess,
  adminEditMode,
  setAdminEditMode,
  pathname,
  templateId,
  goalId,
  queryResult: { refetch },
}) => {
  const { currentUser } = useAuth()

  // Current User Membership groups
  const currentUserMembershipGroupOptions =
    currentUser?.membershipData?.membershipGroups

  // Set the list of selectable membership groups
  // Admin gets all membership groups
  // Members get only assign groups
  const selectableMembershipGroups = userHasAdminAccess
    ? membershipGroups
    : currentUserMembershipGroupOptions

  return (
    <GoalList
      clientId={clientId}
      membershipId={membershipId}
      goals={goals}
      memberships={memberships}
      membershipGroups={selectableMembershipGroups}
      userHasAdminAccess={userHasAdminAccess}
      adminEditMode={adminEditMode}
      setAdminEditMode={setAdminEditMode}
      refetch={refetch}
      pathname={pathname}
      templateId={templateId}
      goalId={goalId}
    />
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<Props> = ({
  clientId,
  membershipId,
  goals,
  memberships,
  membershipGroups,
  userHasAdminAccess,
  adminEditMode,
  setAdminEditMode,
  pathname,
  goalId,
  templateId,
  queryResult: { refetch },
  updating,
}) => {
  const { currentUser } = useAuth()

  // Current User Membership groups
  const currentUserMembershipGroupOptions =
    currentUser?.membershipData?.membershipGroups

  // Set the list of selectable membership groups
  // Admin gets all membership groups
  // Members get only assign groups
  const selectableMembershipGroups = userHasAdminAccess
    ? membershipGroups
    : currentUserMembershipGroupOptions

  return (
    <GoalList
      clientId={clientId}
      membershipId={membershipId}
      goals={goals}
      memberships={memberships}
      membershipGroups={selectableMembershipGroups}
      userHasAdminAccess={userHasAdminAccess}
      adminEditMode={adminEditMode}
      setAdminEditMode={setAdminEditMode}
      refetch={refetch}
      pathname={pathname}
      templateId={templateId}
      goalId={goalId}
      isUpdating={updating}
    />
  )
}
